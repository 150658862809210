export default {
  "LOGIN_PAGE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiez-vous"])},
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les identifiants ne correspondent à aucun compte"])}
  },
  "CGS": {
    "BEGINNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En continuant, vous acceptez la"])},
    "LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["politique de confidentialités"])},
    "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de Meelo"])}
  },
  "DEVELOPMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développement"])},
  "STAGING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recette"])},
  "PRODUCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production"])},
  "TABLE": {
    "TITLES": {
      "SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
      "LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien"])}
    }
  },
  "WELCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue"])},
  "CHOOSE_ENVIRONMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir son environnement"])},
  "SERVICE": {
    "amlcft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aml Cft"])},
    "check_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Company"])},
    "checkout_proxy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])},
    "context_microservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Context Microservice API"])},
    "document_proxy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])},
    "frisk_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisk Service API"])},
    "open_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Banking"])},
    "rules_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meelo Rules Engine"])},
    "scoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring"])},
    "tax_notice_analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Notice Analysis"])},
    "tax_report_analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Report Analysis"])}
  },
  "BUTTONS": {
    "CONNEXION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])}
  }
}