
import {defineComponent} from "vue";
import MeeloHeader from "@/components/MeeloHeader.vue";

export default defineComponent({
  components: {MeeloHeader},
  created(){
      document.title = "Meelo Documentation";
      if (!this.$cookies.get('access_token')){
          this.$router.push('/');
      }
  },
})
