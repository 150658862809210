export default {
  "LOGIN_PAGE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The identifiers do not correspond to any account"])}
  },
  "CGS": {
    "BEGINNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By continuing, you agree the"])},
    "LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacy policy"])},
    "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of Meelo"])}
  },
  "DEVELOPMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development"])},
  "STAGING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staging"])},
  "PRODUCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production"])},
  "TABLE": {
    "TITLES": {
      "SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
      "LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])}
    }
  },
  "WELCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
  "CHOOSE_ENVIRONMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your environment"])},
  "SERVICE": {
    "amlcft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aml Cft"])},
    "check_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Company"])},
    "checkout_proxy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])},
    "context_microservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Context Microservice API"])},
    "document_proxy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])},
    "frisk_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisk Service API"])},
    "open_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Banking"])},
    "rules_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meelo Rules Engine"])},
    "scoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring"])},
    "tax_notice_analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Notice Analysis"])},
    "tax_report_analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Report Analysis"])}
  },
  "BUTTONS": {
    "CONNEXION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])}
  }
}