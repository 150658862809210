import {createApp} from 'vue';

import router from "./router";
import VueCookies from 'vue-cookies';
import App from './App.vue';
import store from './store';
import {BootstrapVue3} from "bootstrap-vue-3";
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import VueAxios from "vue-axios";
import axios from "axios";
import VueJsonPretty from "vue-json-pretty";
// @ts-ignore
import i18n from './i18n';

createApp(App).use(i18n).use(store)
    .use(i18n)
    .use(router)
    .use(VueCookies)
    .use(store)
    .use(BootstrapVue3)
    .use(BootstrapIconsPlugin)
    .use(VueAxios, axios)
    .use(VueJsonPretty)
    .mount('#app');
	