import { createStore } from 'vuex'

export default createStore({
  state: () => ({
    loggedIn: false,
    apiKey: '',

    //USED FOR FRAUD B2C
    customerProfile: {
      identity: {
        lastName: '',
        firstName: '',
        birthDate: '',
        birthPlace: '',
        email: '',
        mobilePhoneNumber: ''
      },
      address: {
        street: '',
        city: '',
        zipCode: '',
        country: ''
      }
    },
    companyProfile: {
      siren: '',
      company_name: '',
    },
  }),
  mutations: {
    login (state, apiKey) {
      state.apiKey = apiKey;
      state.loggedIn = true;
    },
    logout (state) {
      state.apiKey = '';
      state.loggedIn = false;
    },
    setFirstName (state, firstName){
      state.customerProfile.identity.firstName = firstName;
    },
    setLastName (state, lastName){
      state.customerProfile.identity.lastName = lastName;
    },
    setDateOfBirth (state, dob){
      state.customerProfile.identity.birthDate = dob;
    },
    setPlaceOfBirth (state, pob){
      state.customerProfile.identity.birthPlace = pob;
    },
    setEmail (state, email){
      state.customerProfile.identity.email = email;
    },
    setPhone (state, phone){
      state.customerProfile.identity.mobilePhoneNumber = phone;
    },
    setStreet (state, street){
      state.customerProfile.address.street = street;
    },
    setCity (state, city){
      state.customerProfile.address.city = city;
    },
    setZipCode (state, zipCode){
      state.customerProfile.address.zipCode = zipCode;
    },
    setCountry (state, country){
      state.customerProfile.address.country = country;
    },
    setSiren (state, siren){
      state.companyProfile.siren = siren;
    },
    setCompanyName (state, company_name){
      state.companyProfile.company_name = company_name;
    }
  },
  getters: {
    getApiKey(state){
      return state.apiKey;
    },
    getUserStatus(state){
      return state.loggedIn;
    },
    getCustomerProfile(state){
      return state.customerProfile;
    },
    getCompanyProfile(state){
      return state.companyProfile;
    },
    getSiren(state){
      return state.companyProfile.siren;
    },
    getCompanyName(state){
      return state.companyProfile.company_name;
    },
  },
  actions: {
    login (context, apiKey) {
      context.commit('login', apiKey);
    },
    logout (context) {
      context.commit('logout');
    },
    setCustomerIdentity(context, identity){
      context.commit('setFirstName', identity.firstname)
      context.commit('setLastName', identity.lastname);
      context.commit('setDateOfBirth', identity.dob);
      context.commit('setPlaceOfBirth', identity.pob);
      context.commit('setEmail', identity.email);
      context.commit('setPhone', identity.phone);
    },
    setCustomerAddress(context, address){
      context.commit('setStreet', address.street);
      context.commit('setCity', address.city);
      context.commit('setCountry', address.country);
      context.commit('setZipCode', address.zipCode);
    },
    setSiren(context, siren){
      context.commit('setSiren', siren);
    },
    setCompanyName(context, company_name){
      context.commit('setCompanyName', company_name);
    }
  }
})