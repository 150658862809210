export default {
  "LOGIN_PAGE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'accesso non corrisponde a nessun account"])}
  },
  "CGS": {
    "BEGINNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuando, si accettano le"])},
    "LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["informativa sulla privacy"])},
    "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de Meelo"])}
  },
  "DEVELOPMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sviluppo"])},
  "STAGING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricetta"])},
  "PRODUCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produzione"])},
  "TABLE": {
    "TITLES": {
      "SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio"])},
      "LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collegamento"])}
    }
  },
  "WELCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benvenuti"])},
  "CHOOSE_ENVIRONMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegliere l'ambiente"])},
  "SERVICE": {
    "amlcft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aml Cft"])},
    "check_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Company"])},
    "checkout_proxy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])},
    "context_microservice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Context Microservice API"])},
    "document_proxy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])},
    "frisk_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisk Service API"])},
    "open_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Banking"])},
    "rules_engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meelo Rules Engine"])},
    "scoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring"])},
    "tax_notice_analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Notice Analysis"])},
    "tax_report_analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Report Analysis"])}
  },
  "BUTTONS": {
    "CONNEXION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connessione"])}
  }
}